import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-i18nify'
import { Hidden } from 'react-grid-system'

export default class Panel extends Component {
    constructor(props) {
        super(props)

        this.state = { expanded: false }
    }

    render() {
        const { headerText, headerIcon } = this.props

        let headerIconClass = 'panel__header__icon icons ' + headerIcon

        let bodyClass = `panel__body ${this.props.bodyClassName || ''}${
            this.state.expanded ? ' expanded' : ''
        }`

        return (
            <div className={this.props.className}>
                {(this.props.headerText || this.props.headerIcon || this.props.addButton) && (
                    <div className={this.props.headerClass}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <h3>
                                            <i className={headerIconClass} /> {headerText}
                                        </h3>
                                    </td>
                                    <Hidden sm xs>
                                        {this.props.supportText ? (
                                            <td>{this.props.supportText}</td>
                                        ) : (
                                            <td />
                                        )}
                                    </Hidden>
                                    {this.props.addButton && !this.props.addSecondButton && (
                                        <td className="button_container">{this.props.addButton}</td>
                                    )}
                                    {this.props.addButton && this.props.addSecondButton && (
                                        <td className="button_container">
                                            {this.props.addButton}
                                            <div style={{ clear: 'both', height: '5px' }} />
                                            {this.props.addSecondButton}
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                {this.props.subHeader}

                <div className={bodyClass}>{this.props.children}</div>

                {(this.props.className === 'panel--expand' ||
                    this.props.className === 'panel--expand panel--multi__inner' ||
                    this.props.className === 'panel--events panel--expand panel--multi__inner' ||
                    this.props.className === 'panel--expand panel--multi__inner tags' ||
                    this.props.className === 'panel--expand panel--multi__inner tags--mobile' ||
                    this.props.className === 'panel--multi_inner panel--expand' ||
                    this.props.className ===
                        'panel--expand panel--multi__inner panel--multi__inner__uneditable tags' ||
                    this.props.className ===
                        'panel--expand panel--multi__inner panel--multi__inner__uneditable tags--mobile') && (
                    <div
                        className="panel__btn-expand"
                        onClick={() => {
                            this.setState({ expanded: !this.state.expanded })
                        }}
                    >
                        {!this.state.expanded && (
                            <div>
                                <i className="icons icon-arrow-down">&nbsp;&nbsp;</i>
                                <Translate value="profile.see_more" />
                            </div>
                        )}
                        {this.state.expanded && (
                            <div>
                                <i className="icons icon-arrow-up">&nbsp;&nbsp;</i>
                                <Translate value="profile.see_less" />
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

Panel.propTypes = {
    className: PropTypes.string.isRequired,
    bodyClassName: PropTypes.string,
    headerClass: PropTypes.string,
    headerText: PropTypes.string,
    headerIcon: PropTypes.string,
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
    addButton: PropTypes.element,
    addSecondButton: PropTypes.element,
    subHeader: PropTypes.element,
}

Panel.defaultProps = {
    addButton: undefined,
    addSecondButton: undefined,
    bodyClassName: '',
    headerClass: 'panel__header',
}
