import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { translate, Translate } from 'react-i18nify'
import {
    addClass,
    checkForEmptyFields,
    getErrorFields,
    getErrorMessages,
    isRequired,
    removeClass,
} from '../../helpers'
import { diffProfileItems, editProfileItem, fetchStudyLevels } from '../../store/actions/user'

import Actions from '../Actions/Actions'
import Alert from '../Alert/Alert'
import 'react-dates/lib/css/_datepicker.css'
import Select from 'react-select'

let map

/**
 * @property: className: PropTypes.string.isRequired,
 * @property: useOutsideActions: boolean
 * @property: onSave: PropTypes.func.isRequired,
 * @property: onCancel: PropTypes.func.isRequired,
 * @property: dispatch: PropTypes.func.isRequired,
 * @property: edit: PropTypes.object
 */
class FormSimpleStudies extends Component {
    constructor(props) {
        super(props)

        let study_level_options = props.user.study_levels.map((l) => ({
            value: l.id,
            label: l.name,
        }))

        let requiredFields = ['study_level_id']

        if (this.props.edit) {
            let study = this.props.edit

            this.state = {
                id: study.id,
                study_level_id:
                    study_level_options.find((so) => so.value === study.study_level_id) || '',
                finished: study.finished,
                edit: true,
                loading: false,
                errors: [],
                fields: [],
                study_level_options,
            }
        } else {
            this.state = {
                id: -1,
                finished: 1,
                error: '',
                edit: false,
                loading: false,
                errors: [],
                fields: [],
                study_level_options,
                requiredFields,
            }
        }
    }

    componentDidMount() {
        this.props.dispatch(fetchStudyLevels())
    }

    handleChange(value, key, changeClass = true) {
        let obj = {}
        obj[key] = value.target.value

        this.setState(obj)

        if (changeClass) {
            if (value.target.value.length > 0) {
                addClass(value.currentTarget, 'has-value')
            } else {
                removeClass(value.currentTarget, 'has-value')
            }
        }
    }

    cancel() {
        if (this.state.loading) {
            return
        }

        this.state = {
            id: -1,
            errors: [],
            fields: [],
            study_level_id: null,
            finished: 1,
            error: '',
            edit: false,
            loading: false,
        }
        this.props.onCancel()
    }

    save() {
        if (this.state.loading) {
            return
        }
        let empty = checkForEmptyFields(this.state)
        if (empty.length > 0) {
            this.addErrors([translate('errors.empty_field')])
            this.addErrorFields(empty)
            return
        }
        this.setState({
            loading: true,
        })

        let finished = this.state.finished,
            study_level_id = this.state.study_level_id || this.props.user.study_levels[0].id

        let study = {
            finished: finished,
            current: finished == 1 ? 0 : 1,
            study_level_id: study_level_id.value,
        }

        if (this.state.id != -1) {
            study.id = this.state.id
        }

        console.log('Saving education')
        if (this.state.edit) {
            this.props.dispatch(
                editProfileItem(
                    'studies',
                    study,
                    this.props.auth,
                    () => this.onSuccess(),
                    (status, error) => this.onError(status, error),
                ),
            )
        } else {
            this.props.dispatch(
                diffProfileItems(
                    'studies',
                    [study],
                    [],
                    this.props.auth,
                    () => {
                        this.onSuccess()
                    },
                    (s, o) => {
                        this.onError(s, o)
                    },
                ),
            )
        }
    }

    onError(status, object) {
        map = {
            study_level_id: translate('profile.label_study_level'),
        }
        this.setState(
            {
                errors: [],
                fields: [],
                loading: false,
            },
            () => {
                if (object.hasOwnProperty('errors')) {
                    let messages = getErrorMessages(object.errors, map)
                    var fields = getErrorFields(object.errors)

                    this.addErrors(messages)
                    this.addErrorFields(fields)
                }
            },
        )
    }

    addErrors(errors) {
        if (this.props.showErrors) {
            this.props.showErrors(errors)
        } else {
            this.setState({
                errors: errors,
            })
        }
    }

    addErrorFields(fields) {
        this.setState({
            fields: fields,
        })
    }

    getClassNames(item, field) {
        let c = ''

        if (item != null && item != '' && item.length > 0) {
            c += ' has-value'
        }

        if (this.state.fields.indexOf(field) != -1) {
            c += ' error'
        }

        return c
    }

    onSuccess() {
        console.log('success saving education')
        this.setState({
            errors: [],
            fields: [],
            id: -1,
            study_level_id: null,
            finished: 1,
            error: '',
            edit: false,
            loading: false,
        })
        this.props.onSave()
    }

    getSaveClasses() {
        let c = 'actions__action--save'

        if (this.state.loading) {
            c += ' is-loading'
        }

        return c
    }

    render() {
        const verified = this.props.edit ? !!this.props.edit.verified : false

        return (
            <form
                className={this.props.className}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        this.save()
                    }
                }}
            >
                <fieldset className="form__fieldset">
                    {this.state.errors && this.state.errors.length > 0 && (
                        <Alert type="alert--error alert--simple">
                            <ul>
                                {this.state.errors.map(function (value) {
                                    return <li key={value}>{value}</li>
                                })}
                            </ul>
                        </Alert>
                    )}

                    <div className={'form__item' + isRequired('study_level_id', this.state)}>
                        <i className="form__icon icons icon-graduation" />
                        <Select
                            className={
                                'select--alt' +
                                (this.props.darkMode ? '-black' : '') +
                                this.getClassNames(this.state.study_level_id, 'study_level_id')
                            }
                            classNamePrefix="select"
                            value={this.state.study_level_id}
                            onChange={(value) =>
                                this.handleChange(
                                    { target: { value: value } },
                                    'study_level_id',
                                    false,
                                )
                            }
                            tabIndex="1"
                            options={this.state.study_level_options}
                            placeholder={translate('profile.study_levels_placeholder')}
                            id={'study_level'}
                        ></Select>
                        <label className="form__label" htmlFor="study_level">
                            <Translate value="profile.label_study_level" />
                        </label>
                    </div>
                    <div className={'form__item' + isRequired('finished', this.state)}>
                        <label className="form__top_label" style={{ left: '0' }}>
                            Diploma behaald?
                        </label>
                        <div className="form__radio">
                            <input
                                type="radio"
                                name="radio_styled"
                                id="study_graduated_yes"
                                className="form__radio__input"
                                checked={this.state.finished == 1}
                                onChange={(value) => this.handleChange(value, 'finished', false)}
                                value="1"
                                tabIndex="6"
                                disabled={verified}
                            />
                            <label className="form__radio__label" htmlFor="study_graduated_yes">
                                <Translate value="profile.graduated_yes" />
                            </label>
                        </div>
                        <div className="form__radio">
                            <input
                                type="radio"
                                name="radio_styled"
                                id="study_graduated_no"
                                className="form__radio__input"
                                checked={this.state.finished != 1}
                                onChange={(value) => this.handleChange(value, 'finished', false)}
                                value="0"
                                tabIndex="7"
                                disabled={verified}
                            />
                            <label className="form__radio__label" htmlFor="study_graduated_no">
                                <Translate value="profile.graduated_no" />
                            </label>
                        </div>
                    </div>
                </fieldset>
                {this.props.showActions && (
                    <Actions>
                        <Link
                            className="actions__action--cancel"
                            onClick={() => {
                                this.cancel()
                            }}
                            tabIndex="8"
                        >
                            <Translate value="profile.cancel" />
                        </Link>
                        <Link
                            className={this.getSaveClasses()}
                            onClick={() => this.save()}
                            tabIndex="9"
                        >
                            <Translate value="profile.save" />
                        </Link>
                    </Actions>
                )}
            </form>
        )
    }
}

FormSimpleStudies.propTypes = {
    className: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    edit: PropTypes.object,
    showActions: PropTypes.bool,
}

function mapStateToProps(state) {
    const { auth, user } = state

    return {
        auth,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, null, null, { forwardRef: true })(FormSimpleStudies)
